import React from "react";
import { Link } from "gatsby";
import { Box, Image, T5 } from "../styles/Styles";
import image from "../img/api-thumb.png";
import theme from "../styles/theme";

const CardProductAPI = ({noLink}) => {
  return (
    <Box display="flex" flexDirection="column" bg={theme.color.light} borderRadius={8} height={['auto', '100%']} mb={4}>
      <Box px={[4]} pt={4} mb="auto">
        <T5 mb={4}>API</T5>
        <Box mb={3}>
            最先端の気象予報APIは、世界中いたる地域の最も正確な<br/>気象予報と大気質データを提供します。プロダクトを作り、tomorrow.io&nbsp;のパワフルなデータにアルゴリズムを取り入れた<br/>プロダクトマネジャーと開発者のコミュニティに参加しま<br/>せんか。
        </Box>
        {!noLink && <Link to="/weather-api/"><strong>もっと詳しく ›</strong></Link>}
      </Box>
      <Box pl={4}>
        <Image src={image} alt=''/>
      </Box>
    </Box>
  );
};

export default CardProductAPI;