// fill hidden fields of the hubspot form

export const fillFormWithUtms = () => {
  const campaign = document.querySelector('input[name="utm_campaign"]');
  const source = document.querySelector('input[name="utm_source"]');
  const medium = document.querySelector('input[name="utm_medium"]');
  const keyword = document.querySelector('input[name="utm_keyword"]');
  const term = document.querySelector('input[name="utm_term"]');
  const referringPage = document.querySelector('input[name="referring_page"]');

  if (campaign) {
    campaign.value = localStorage.getItem("utm_campaign");
  }
  if (source) {
    source.value = localStorage.getItem("utm_source");
  }
  if (medium) {
    medium.value = localStorage.getItem("utm_medium");
  }
  if (keyword) {
    keyword.value = localStorage.getItem("utm_keyword");
  }
  if (term) {
    term.value = localStorage.getItem("utm_term");
  }
  if (referringPage) {
    referringPage.value = sessionStorage.getItem("referring_page");
  }
};
