import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap";

import all from "../../img/icon-all-industries-color.svg";
import avia from "../../img/icon-avia-color.svg";
import energy from "../../img/icon-energy-color.svg";
import constr from "../../img/icon-construction-color.svg";
import drones from "../../img/icon-drones-color.svg";
import tech from "../../img/icon-automotive-color.svg";
import out from "../../img/icon-outdoor-color.svg";

const industries = [
  {
    name: "航空",
    color: "#B9E0FF",
    icon: avia,
    indPath: "/industry/aviation/",
    jobs: [
      "除氷に必要なリソース",
      "ランプ閉鎖や開放を予想",
      "顧客と従業員を守る",
      "設備損傷を防止",
      "地上遅延プログラムを予想",
      "タールマック規則違反を減少",
      "代替着陸を予想",
      "正しい滑走路の構成を知る",
      "旅客の遅延に対する認識",
      "VFRやIFR変更の計画",
      "共通のツールでコラボ",
      "最適な飛行ルートを調整",
      "時間の経過に伴う変化を認識",
      "定期的な閉鎖や開放を予想",
      "システム全体の影響を表示"
    ]
  },
  {
    name: "エネルギー",
    color: "#F8E8B2",
    icon: energy,
    indPath: "/industry/energy/",
    jobs: [
      "仕事のスケジュール管理を最適化",
      "山火事リスクを認識",
      "貿易異常で実行",
      "消費者のニーズを予測",
      "停電に備える",
      "設備損傷を防止",
      "再生可能資産の生成を予想",
      "分配の効率化をランク付け",
      "顧客と従業員を守る",
      "資産メインテナンスを予定する",
      "共通のツールでコラボ"
    ]
  },
  {
    name: "建設",
    color: "#D9D9D9",
    icon: constr,
    indPath: "/industry/construction/",
    jobs: [
      "仕事のスケジュール管理を最適化",
      "適切なタイミングで作業中止",
      "崩壊を防止",
      "適切な状況で資材を投入",
      "ゆっくりとした資材の乾燥に備える",
      "設備損傷を防止",
      "過去の気象記録",
      "持参する適切な設備を確認",
      "適切なタイミングで屋根をリッピング",
      "ひとつの傷も回避",
      "顧客と従業員を守る",
      "共通のツールでコラボ"
    ]
  },
  {
    name: "ドローン",
    color: "#D6E0EC",
    icon: drones,
    indPath: "/industry/drones/",
    jobs: [
      "飛行遂行、取止めの決断をする",
      "フライト計画の最適化",
      "設備損傷を防止",
      "バッテリー損失の保護",
      "ドローンモデルに応じた飛行の可否を確認",
      "安全に飛行できる高度に注意",
      "着氷防止剤を投入するタイミングを確認",
      "低衝突の認識を予想",
      "安全な帰路地点を計算",
      "効率的で安全な飛行ルートを計画"
    ]
  },
  {
    name: "テクノロジー",
    color: "#CFCCFD",
    icon: tech,
    indPath: "/industry/demand/",
    jobs: [
      "顧客ニーズを予想",
      "お客様体験の向上",
      "フリート分布を最適化",
      "設備損傷を防止",
      "顧客への気象アラート",
      "天候に応じた価格修正",
      "適した商品の販売",
      "アプリに天候と追加",
      "最適なナビを提案",
      "在庫とメニューを管理",
      "顧客と従業員を守る",
      "ツールに天候を導入"
    ]
  },
  {
    name: "アウトドア",
    color: "#BCE8B5",
    icon: out,
    indPath: "/industry/outdoor/",
    jobs: [
      "開催地の気候制御を最適化",
      "遅延や欠航を予想",
      "適切なタイミングで屋根を開閉",
      "設備損傷を防止",
      "顧客ニーズに応じた在庫計画",
      "放送を充実させる",
      "顧客に最適な商品の販売",
      "顧客に注意喚起",
      "天候に合わせフィールドを準備",
      "顧客と従業員を守る"
    ]
  }
];

const IndustryFilter = ({ isMobile }) => {
  const [selected, setSelected] = React.useState(industries);
  const [blink, setBlink] = React.useState(true);
  const showLabels = !isMobile || (isMobile && selected.length === 1);
  const onSelect = tab => {
    let selectedItem = industries.filter(industry => industry.name === tab);
    setSelected(selectedItem);
    setBlink(false);
  };

  return (
    <>
      <div className="container">
        {isMobile ? (
          <UncontrolledButtonDropdown>
            <DropdownToggle
              caret
              color="primary"
              className="mb-3 d-flex align-items-center"
            >
              {selected.length > 1 ? "Choose Your Industry" : selected[0].name}
            </DropdownToggle>
            <DropdownMenu>
              {industries.map((item, i) => (
                <DropdownItem key={i} onClick={() => onSelect(item.name)}>
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <>
            <h3 className="h-3-sm mb-3 mb-md-6" style={{ maxWidth: 750 }}>
              産業を選択して、すぐに提供される<br/>自動的インサイトのタイプを確認
            </h3>
            <div className="d-flex flex-wrap">
              <Tab
                active={selected.length > 1}
                blink={blink}
                onClick={() => setSelected(industries)}
              >
                <img src={all} alt=""/>
                <p>すべての産業</p>
              </Tab>
              {industries.map(item => (
                <Tab
                  active={
                    selected.length === 1 && selected[0].name === item.name
                  }
                  onClick={() => onSelect(item.name)}
                  key={item.name}
                >
                  <img src={item.icon} alt=""/>
                  <p>{item.name}</p>
                </Tab>
              ))}
            </div>
          </>
        )}
      </div>

      {showLabels && (
        <div style={{ background: "#f6f6f6" }}>
          <div className="container pt-3 pb-2 py-md-5">
            {selected.map((item, index) => {
              let sliceNumber = selected.length > 1 ? 7 : 100;
              return (
                <span key={index}>
                  {item.jobs.slice(0, sliceNumber).map((job, subindex) => (
                    <Label color={item.color} key={index + subindex + 1}>
                      {job}
                    </Label>
                  ))}
                  {selected.length === 1 && (
                    <Link className="fw-800 link-inherit" to={item.indPath}>
                      <Label color="#0072f5">もっと詳しく</Label>
                    </Link>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      )}

    </>
  );
};

export default IndustryFilter;

const Tab = styled.div`
  width: 120px;
  height: 90px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${props => (props.active ? "#0072f5" : "white")};
  transition: all 0.2s;
  animation: ${props =>
  props.active && props.blink ? "blink 1.25s infinite linear both" : "none"};

  &:hover {
    img,
    p {
      transition: all 0.2s;
      opacity: 0.6;
    }
  }

  @keyframes blink {
    50% {
      border-bottom-color: #d2e0ff;
    }
  }

  img {
    margin-bottom: 0.75rem;
  }
  p {
    margin-bottom: 0.9rem;
    font-weight: 900;
    font-size: 15px;
  }
`;

const Label = styled.span`
  border-radius: 4px 4px 0 0;
  border-bottom: ${props => `2px solid ${props.color}`};
  font-size: 0.7rem;
  background-color: white;
  color: #6c757d;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  display: inline-block;
  @media only screen and (min-width: 769px) {
    font-size: 0.87rem;
    padding: 0.5rem;
  }
`;
