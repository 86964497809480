import React from "react";
import {Link} from "gatsby";
import {Box, Image, T5} from "../styles/Styles";
import image from "../img/hypercast-thumb.png";
import theme from "../styles/theme";

const CardProductHypercast = ({noLink, isHoop}) => {
    return (
        <Box display="flex" flexDirection="column" bg={theme.color.secondary} color="white" borderRadius={8}
             height={["auto", "100%"]} mb={4}>
            <Box px={4} pt={4} mb="auto">
                <T5 mb={4} dangerouslySetInnerHTML={{__html: isHoop ? `H.O.O.P.<sup>™</sup>` : `tomorrow.io`}}/>
                <Box mb={3}>
                    <span dangerouslySetInnerHTML={{__html: isHoop ? `H.O.O.P.<sup>™</sup>` : `tomorrow.io`}}/>は、Platformの画期的な気象予報プラット<br/>フォーム。天候に左右されやすい産業の企業に導入され、<span
                    dangerouslySetInnerHTML={{__html: isHoop ? `H.O.O.P.<sup>™</sup>` : `tomorrow.io`}}/>&nbsp;は天候に関わる特殊な質問に答え、オペレー<br/>ションの最適化を図ります。安全性を高め、資産を保護し、<br/>損失を減少、そしてビジネス機会を手にすることができる<br/>よう導きます。
                </Box>
                {!noLink && <Link to="/software/"><strong>もっと詳しく ›</strong></Link>}
            </Box>
            <Box pl={4}>
                <Image src={image} alt=''/>
            </Box>
        </Box>
    );
};

export default CardProductHypercast;