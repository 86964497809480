import React from "react";
import { Link } from "gatsby";
import { Box, T5 } from "../styles/Styles";
import image from "../img/api-page-1.png";

const CardProductWAI = () => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" borderRadius={8} border="1px solid rgba(0,0,0,.1)" mb={4}>
      <Box width={[1, 1 / 2]} px={[4, 5]} py={4}>
        <T5 mb={2}>WAI: Weather for AI (気象予報にAI)</T5>
        <Box fontWeight={800} mb={3}>過去の気象データアーカイブをAIモデルトレーニングに活用</Box>
        <Box mb={3}>
            世界中いたる地域のもっとも正確な情報を手に入れ、AIによるインサイトで、天気がビジネスに及ぼす影響をより的確に予測
        </Box>
        <Link to="/historical-weather-data/"><strong>もっと詳しく ›</strong></Link>
      </Box>
      <Box width={[1, 1 / 2]} minHeight={350} background={`url(${image}) no-repeat center 20px / 95%`} />
    </Box>
  );
};

export default CardProductWAI;