import React from "react";
import { Box, T5 } from "../styles/Styles";
import image from "../img/org-thumb.png";
import theme from "../styles/theme";

const CardProductOrg = () => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" bg="white" borderRadius={8} overflow="hidden" mb={4}>
      <Box width={[1, 1 / 2]} px={[4, 5]} py={4}>
        <T5 mb={3}>tomorrownow.org</T5>
        <Box mb={3}>
            グローバルな気象データへのアクセスを向上させることに<br/>より、多くの尊い命を救い、変化を生むことができる。
        </Box>
        <Box as="a" href="https://www.tomorrownow.org/" color={theme.color.fresh}><strong>もっと詳しく ›</strong></Box>
      </Box>
      <Box width={[1, 1 / 2]} minHeight={300} background={`url(${image}) no-repeat center 10px / 45%`}/>
    </Box>
  );
};

export default CardProductOrg;