import React from "react";
import wash from "../../img/washington-logo.svg";
import mit from "../../img/mit-logo.svg";
import enr from "../../img/enr-logo.svg";
import tc from "../../img/tc-logo.svg";
import mash from "../../img/mashable-logo.svg";
import wsj from "../../img/wall-street-logo.svg";
import bloom from "../../img/bloomberg-logo.svg";
import fortune from "../../img/fortune-logo.svg";
import fast from "../../img/fast-logo.svg";
import time from "../../img/time-logo.svg";
import bi from "../../img/business-insider-logo.svg";
import etimes from "../../img/etimes-logo.svg";
import forbes from "../../img/forbes-logo.svg";
import nweek from "../../img/newsweek-logo.svg";
import wired from "../../img/wired-logo.svg";

const items = [
  {
    logo: wash,
    title:
      "tomorrow.ioの気象予報アプリを使ってみていただきたい。世界中どこにいても、雨が降るのを分単位で知らせてくれる。",
    link:
      "https://www.washingtonpost.com/weather/2019/08/12/meet-climacell-weather-app-alerting-you-when-its-about-rain-down-minute-all-around-world/",
  },
  {
    logo: mit,
    title:
      "「私たちの航空会社が他より往々にして成功しました。それというのも、一般の気象予報に頼らず、tomorrow.ioの情報を頼ったからです」ボストンを拠点とする❝気象予報テク❞スタートアップは、他の何よりも正確に気象予報ができると自信をもっている。",
    link:
      "https://www.technologyreview.com/s/613445/a-weather-tech-startup-wants-to-do-forecasts-based-on-cell-phone-signals/",
  },
  {
    logo: bi,
    title:
      "雷はいつ発生するか？除氷車をいつ発動させるか？より正確な気象予報があることで、よりシームレスな計画を立てる事できる。",
    link:
      "https://www.businessinsider.com/jetblue-venture-capital-reveals-top-investments-travel-disruptions-weather-delays-2019-10",
  },
  {
    logo: enr,
    title:
      "最先端の気象予報サービスが、より正確な気象予報を必要とされる航空業界や他の産業で取り入れられ、近日建設業界にも進出。早期導入をした屋根修理業者は、既にその詳細な気象予報とアラートを頼りとしている。",
    link:
      "https://www.enr.com/articles/47297-micro-forecasting-taps-into-the-weather-internet-of-things",
  },
  {
    logo: tc,
    title:
      "tomorrow.ioの主要な技術革新がもたらしているのは、政府のセンサーに頼ることなしに、IoTを駆使し、それ以上はないというような地域のデータを集めていることである。",
    link:
      "https://techcrunch.com/2019/03/18/climacell-bets-on-iot-for-better-weather-forecasts/",
  },
  {
    logo: mash,
    title: "tomorrow.ioの気象予報アプリは、正確性とアラートを兼ね備えている。",
    link: "https://mashable.com/article/climacell-weather-app-dark-sky/",
  },
  {
    logo: etimes,
    title:
      "tomorrow.ioは、環境センサーとして日々利用するデバイスで、予期される洪水を人々に注意喚起するといった、正確な気象予報を提供している。",
    link:
      "https://economictimes.indiatimes.com/small-biz/startups/features/baked-by-ratan-tata-softbank-this-startup-predicts-when-the-next-flood-will-hit-you-climacell/articleshow/69019435.cms",
  },
  {
    logo: wsj,
    title:
      "tomorrow.ioは、遠く離れていても気象を予報できる。雷を伴った嵐がJFKに接近しているとき、tomorrow.ioは携帯電話の基地局を利用し、ひとつの基地局から他への送信を追跡し、降水量をモニター。そして、特定地域の気象を予報するのである。",
    link:
      "https://www.wsj.com/articles/what-jetblue-is-doing-in-silicon-valley-11572184801",
  },
  {
    logo: bloom,
    title:
      "Startup Street：ラタン・タタ氏が支援するスタートアップは、何日も前にインドでの洪水を予報することができる。",
    link:
      "https://www.bloombergquint.com/technology/startup-street-this-ratan-tata-backed-startup-predicts-floods-in-india-days-in-advance",
  },
  {
    logo: fortune,
    title:
      "tomorrow.ioの新型気象予報アプリは、6億のデバイスを駆使し、町のひとブロック同様に広範囲の気象を予報する。",
    link: "https://fortune.com/2019/08/13/climacell-best-weather-forecast-app/",
  },
  {
    logo: fast,
    title:
      "tomorrow.ioのウェザー・オブ・シングスによる気象予報アプローチは、都市計画者が悪天候に対応できるようサポートしている。",
    link:
      "https://www.fastcompany.com/90342648/your-smartphones-wireless-signal-can-be-used-to-forecast-the-weather",
  },
  {
    logo: time,
    title:
      "気象予報の競争内情は、あなたが頼りにする日常の予報を脅かすかもしれない。",
    link:
      "https://time.com/5615625/private-companies-weather-forecasting-threat/",
  },
  {
    logo: forbes,
    title:
      "tomorrow.ioは、データ分析、従来の考え方、携帯電話の基地局からの通信シグナルの分析を掛け合わせ、6時間前に地域の気象予報をする。これはまさに、ビジネスのゲームチェンジャーである。",
    link:
      "https://www.forbes.com/sites/jonmarkman/2019/07/30/this-startup-uses-cell-tower-signals-to-accurately-predict-weather/",
  },
  {
    logo: nweek,
    title:
      "農夫は作物への灌水が必要かどうかを、現在では正確に知ることができる。空港では、航空機の除雪が必要かどうかを情報によって準備でき、ルーファーは一日を無駄にしてしまうかどうかの警告を受取ることができる。",
    link:
      "https://www.newsweek.com/israel-politics-paralyzed-economy-tech-fine-1469659",
  },
  {
    logo: wash,
    title:
      "tomorrow.ioは、アフリカで気象予報を向上する、非営利団体を形成。",
    link:
      "https://www.washingtonpost.com/weather/2019/12/03/private-weather-company-climacell-spin-off-nonprofit-tackle-weather-forecasting-early-warnings-africa/ ",
  },
  {
    logo: wired,
    title:
      `ボストンを拠点とするスタートアップ企業は、インターネットに接続されたセンサー、航空機から気象観測を収集し、JetBlueやニュー・イングランド・パトリオットといったクライアントに予報情報を提供。`,
    link:
      "https://www.wired.com/story/whats-in-the-forecast-private-weather-predictions/",
  },

];

const MediaMentions = ({ isMobile }) => {
  const numberOnInit = isMobile ? 3 : 100;
  const [number, setNumber] = React.useState(numberOnInit);

  return (
    <div className="container">
      <div className="row">
        {items.slice(0, number).map((item, i) => (
          <div
            key={i}
            className="col-md-6 col-lg-3"
            style={{ marginBottom: 30 }}
          >
            <div
              className="d-flex p-4 flex-column rounded-lg bg-white h-100"
              style={{ minHeight: 300, border: "solid 1px #ebebeb" }}
            >
              <div className="mb-4 mr-6">
                <img
                  className="img-fluid"
                  src={item.logo}
                  style={{ maxHeight: 30 }}
                  alt=""
                />
              </div>
              <p className="mb-auto text-muted">{item.title}</p>
              <a href={item.link} className="fw-900 pt-2">
                記事を参照 ›
              </a>
            </div>
          </div>
        ))}
      </div>
      {isMobile && number === 3 && (
        <button className="btn btn-primary" onClick={() => setNumber(100)}>
          Show more
        </button>
      )}
    </div>
  );
};

export default MediaMentions;
