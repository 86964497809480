import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box, Image, Lead, Container } from "../../styles/Styles";
import theme from "../../styles/theme";
import hist from "../../img/historical-thumb.png";
import real from "../../img/real-time-thumb.png";

const nowcast = "https://cms2.climacell.co/wp-content/uploads/2020/01/nowcasting-thumb.gif";
const cbam = "https://cms2.climacell.co/wp-content/uploads/2020/01/cbam-thumb.gif";

const timeline = [
  {
    title: "過去",
    sub: "(-20年間分)",
    text: `AIモデルトレーニングの為の、<br/>高解像度の過去データセット`,
    icon: hist,
    pos: "left",
    link: "/historical-weather-data/"
  },
  {
    title: "リアルタイム",
    sub: "&nbsp;",
    text: `グリッド高解像度、高リフレッシュデータ。<br/>バーチャルセンサーを至る所に。`,
    icon: real,
    pos: "center",
    link: null
  },
  {
    title: "ナウキャスティング",
    sub: "(+6時間)",
    text: `分単位の3－6時間にわたる降水量予報`,
    icon: nowcast,
    pos: "center",
    link: null
  },
  {
    title: "CBAM",
    sub: "(+14日間 )\n",
    text: `NWPプラットフォーム 必要な、<br/>至る地域の高解像度の予報`,
    icon: cbam,
    pos: "right",
    link: "/cbam/"
  }
];

const ModelsTimeline = () => {

  return (
    <Container>
      <Box display='flex' justifyContent='center' flexWrap='wrap' textAlign="center">
        {timeline.map((item, i) => {
          return (
            <Item pos={item.pos} width={[1, 1 / 2, 1 / 4]} px={[3]} key={item.title} className={item.pos}>
              <Box mb={[2, 2, 5]}>
                <Image width={250} src={item.icon} alt=""/>
              </Box>
              <Box fontWeight={800} color={i === 1 ? theme.color.primary : null}>
                <Lead mb={1}>{item.title}</Lead>
                <Box mb={3} dangerouslySetInnerHTML={{ __html: item.sub }}/>
              </Box>
              <Box fontSize={1} dangerouslySetInnerHTML={{ __html: item.text }}/>
              {item.link && <Link to={item.link}><Box as="strong" fontSize={2}>もっと詳しく&nbsp;›</Box></Link>}
            </Item>
          );
        })}
      </Box>
    </Container>
  );
};

export default ModelsTimeline;

const Item = styled(Box)`
  position: relative;
  margin-bottom: 3rem;
  @media only screen and (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 8px;
      background-color: #e7e7e7;
    }
    &:after {
      content: "";
      position: absolute;
      top: 237px;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 13px;
      background-color: #e7e7e7;
    }
    &.left {
      &:before {
        left: auto;
        right: -1px;
        transform: unset;
        width: calc(50% + 4px);
      }
    }
    &.right {
      &:before {
        left: -1px;
        transform: unset;
        width: calc(50% + 4px);
      }
    }
  }
`;