import React, { useEffect } from "react";
import { fillFormWithUtms } from "./Utils/fillFormWithUtms";

const HsForm = props => {
  useEffect(() => {
    // getting referring page
    const cururl = document.referrer || "Direct Link";
    sessionStorage.setItem("referring_page", cururl);

    // loading and creating hubspot form
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: props.portalId,
          formId: props.formId,
          sfdcCampaignId: props.sfdcCampaignId,
          target: `#${props.nodeId}` || `#hubspot-form`,
          cssClass: props.cssClass,
          submitButtonClass: props.submitButtonClass,
          errorClass: props.errorClass,
          errorMessageClass: props.errorMessageClass,
          onFormReady: () => fillFormWithUtms(),
        });
      }
    });

    // hacking hs to ignore jquery
    window.jQuery =
      window.jQuery ||
      (() => ({
        change: () => {},
        trigger: () => {},
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <div id={`${props.nodeId}` || `hubspot-form`} />;
};

export default HsForm;
