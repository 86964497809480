import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import videoPoster from "../../img/secondary-video-poster.png";
import HsForm from "../HsForm";

const Hero = ({title, subtitle}) => {
  const [isGif, setIsGif] = React.useState(false);

  React.useEffect(() => {
    const isUiWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
      navigator.userAgent
    );
    if (isUiWebView) {
      setIsGif(true);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-7 pr-lg-6">
          <h1 className="h-1-s mb-4 pr-lg-4" dangerouslySetInnerHTML={{__html: title}}/>
          <p className="lead mb-4 pr-lg-4" dangerouslySetInnerHTML={{__html: subtitle}}/>
          <div className="d-flex mb-4 mb-lg-0" style={{minHeight: 40}}>
            <HsForm
              portalId="3777859"
              sfdcCampaignId="701460000007ZaMAAU"
              formId="d4394426-0075-4d82-91d4-67b43fc10f26"
              cssClass="hs-form-unstyled hs-form-unstyled-email"
              submitButtonClass="btn btn-primary"
            />
          </div>
        </div>
      </div>

      <VideoWrap className="embed-responsive embed-responsive-16by9">
        {isGif ? (
          <img
            src="https://cms2.climacell.co/wp-content/uploads/2019/12/main-video.gif"
            className="embed-responsive-item shadow"
            alt=""
          />
        ) : (
          <ReactPlayer
            url="https://cms2.climacell.co/wp-content/uploads/2019/12/main-video.mp4"
            playing
            controls={false}
            muted
            playsinline
            loop
            className="embed-responsive-item shadow"
            width="100%"
            height="100%"
            poster={videoPoster}
          />
        )}
      </VideoWrap>
    </>
  );
};

export default Hero;

const VideoWrap = styled.div`
  position: static;
  width: 100%;
  transform: translateY(0);
  @media screen and (min-width: 992px) {
    width: 45% !important;
    position: absolute !important;
    right: -10px;
    top: 50%;
    transform: translateY(-45%) !important;
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.1);
  }
`;
