import React from "react";
import { Box, Color, Image, Lead } from "../../styles/Styles";
import wild from "../../img/wildfire-thumb.png";
import road from "../../img/road-thumb.png";
import flood from "../../img/flood-thumb.png";
import aq from "../../img/aq-thumb.png";
import light from "../../img/lightning-thumb.png";
import theme from "../../styles/theme";

const items = [
  { title: "山火事リスク", image: wild, link: null },
  { title: "道路リスク", image: road, link: "/road-risk-scores/" },
  { title: "洪水リスク", image: flood, link: '/urban-flooding-forecast/' },
  { title: "大気質", image: aq, link: '/air-quality/' },
  { title: "雷リスク", image: light, link: null }
];

const Solutions = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" textAlign="center">
      {items.map((item, i) => (
        <Box as="a" display="block" href={item.link} width={[1, 1 / 2, 1 / 3]} mb={4} key={i}>
          <Image width={278} src={item.image} mb={3}/>
          <Lead color="black" mb={0}>
            <strong>{item.title}</strong>
          </Lead>
          {item.link && <Color color={theme.color.primary}>もっと詳しく&nbsp;›</Color>}
        </Box>
      ))}
    </Box>
  );
};

export default Solutions;


