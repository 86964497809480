import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import withSizes from "react-sizes";
import LazyLoad from "react-lazyload";
import {Box, Button, Image} from "../styles/Styles";
import Clients from "../components/Homepage/Clients";
import Hero from "../components/Homepage/Hero";
import IndustryFilter from "../components/Homepage/IndustryFIlter";
import IndustriesCarouselHome from "../components/Homepage/IndustriesCarouselHome";
import CardProductHypercast from "../components/CardProductHypercast";
import CardProductAPI from "../components/CardProductAPI";
import CardProductWAI from "../components/CardProductWAI";
import CardProductOrg from "../components/CardProductOrg";
import MediaMentions from "../components/Homepage/MediaMentions";
import ModelsTimeline from "../components/Homepage/ModelsTimeline";
import Solutions from "../components/Homepage/Solutions";
import Modali, {useModali} from "modali";
import iconWaves from "../img/icon-waves.svg";
import iconCubes from "../img/icon-cubes.svg";
import reportBlue from "../img/report-blue.png";
import reportGreen from "../img/report-green.png";
import embry from "../img/embry-riddle-aeronautical-university-logo.svg";
import footer from "../img/bg-contact.png";
import heroBg from "../img/half-hex-blue.svg";
import halfhex2 from "../img/half-hex-blue-2.svg";
import featureData from "../img/feature-data.png";
import featureModels from "../img/feature-models.png";
import featureEngine from "../img/feature-engine.png";
import featureInfo from "../img/feature-information.png";
import featureIns from "../img/feature-insights.png";
import featureAct from "../img/feature-actions.png";
import milrad from "../img/shawn-milrad-hex-color.png";


const Home = ({isMobile}) => {

    const [modal, toggleModal] = useModali({
        animated: true,
        closeButton: false
    });

    return (
        <Layout page="home">
            <SEO
                title="tomorrow.io – もう天気予報を気にしない"
                description="私たちのAIによる天候インテリジェンス・ソフトウェアは、過去、現在、未来の天候によるビジネスへの影響を考慮し、オペレーションの意思決定や行動計画を自動化します。"
                pathname="/"
            />

            <img src={heroBg} className="bg-hero-hp" alt=""/>

            <section className="c-section" style={{zIndex: 5}}>
                <div className="container pt-lg-4">
                    <Hero
                        title={`もう天気予報を<br/>気にしない`}
                        subtitle={`tomorrow.ioが提供する、AIによる天候インテリジェンスソフトウェアは、過去、リアルタイム、未来の天候によるビジネスへの影響を考慮し、オペレーションの<br/>意思決定や行動計画を自動化します。`}
                    />
                </div>
            </section>

            <section className="c-section py-0 py-lg-6">
                <div className="container">
                    <h2 className="h-3 mb-5 mb-lg-7" style={{maxWidth: 750}}>
                        世界中の人々が信頼を寄せるブランド・企業に<br/>導入されていることは、大変な誇りです。
                    </h2>
                    <Clients/>
                </div>
            </section>

            <section className="c-section c-section-overflow-auto py-5">
                <div className="container">
                    <img className="mb-3" width={40} src={iconWaves} alt=""/>
                    <p className="lead text-muted mb-3">
                        今まで、このように気象を観測することはできませんでした
                    </p>
                    <h2 className="h-2-sm mb-5 mb-lg-8">
                        気象予報は、本質的な価値がありません。<br/>意思決定を通知できた際に、初めて価値が生まれます。<br/>
                        シェーン・グラス、Google Cloud
                    </h2>
                </div>
                <LazyLoad height={700} once>
                    <IndustryFilter isMobile={isMobile}/>
                </LazyLoad>
            </section>


            <section className="c-section py-5">
                <LazyLoad height={600} once>
                    <div className="container">
                        <h2 className="h-3 mb-5 mb-lg-7">
                        tomorrow.ioの仕組み
                        </h2>
                        <div className="row mb-5">
                            <div className="col-lg-4 mb-5 mb-lg-0 text-center">
                                <Image
                                    mb={5}
                                    maxWidth={216}
                                    src={featureInfo}
                                    alt=""
                                />
                                <h4 className="h-4 fw-400 mb-3 bg-number">
                                    正しい情報の提供
                                </h4>
                                <p>
                                    世界で最も正確な、気象データを“過去、<br/>リアルタイム、予報”でグローバルに入手
                                </p>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0 text-center pt-lg-6">
                                <Image
                                    mb={5}
                                    maxWidth={294}
                                    src={featureIns}
                                    alt=""
                                />
                                <h4 className="h-4 fw-400 mb-3 bg-number bg-number-2">
                                    ビジネスに貴重な気づきを提供
                                </h4>
                                <p>
                                    天候がビジネスに及ぼす影響の<br/>インサイトをチームに提供
                                </p>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0 text-center pt-lg-9">
                                <Image
                                    mb={5}
                                    width={286}
                                    src={featureAct}
                                    alt=""
                                />
                                <h4 className="h-4 fw-400 mb-3 bg-number bg-number-3">
                                    コラボレーションと行動を導く
                                </h4>
                                <p>
                                    気象事象に計画性をもって対策、<br/>そしてより良い意思決定をする
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button
                                width={[1, "auto"]}
                                display={["block", "inline-block"]}
                                mb={3}
                                onClick={toggleModal}
                            >
                                ビデオをみる
                            </Button>
                        </div>
                        <Modali.Modal {...modal}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    className="embed-responsive-item"
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/t1J3g_uFijc?rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                                    title="tomorrow.io's Platform"
                                />
                            </div>
                        </Modali.Modal>
                    </div>
                </LazyLoad>
            </section>


            <section className="c-section pb-0">
                <div className="container">
                    <h3 className="h-3 mb-5">お客様の声</h3>
                    <LazyLoad height={600} offset={600} once>
                        <IndustriesCarouselHome/>
                    </LazyLoad>
                </div>
            </section>

            <section className="c-section pt-5">
                <div className="container">
                    <h3 className="h-3 mb-7">tomorrow.ioの採用企業各社様の声</h3>
                </div>
                <div className="position-relative">
                    <img
                        src={halfhex2}
                        className="d-none d-lg-block"
                        style={{
                            position: "absolute",
                            width: "35%",
                            left: 0,
                            top: 0,
                            zIndex: -1
                        }}
                        alt=""
                    />
                    <MediaMentions isMobile={isMobile}/>
                </div>
            </section>

            <section className="c-section pt-5 pb-0">
                <div className="container">
                    <img className="mb-3" width={68} src={iconCubes} alt=""/>
                    <p className="lead text-muted mb-3">
                        他の気象予報が外れるわけは？
                    </p>
                    <h2 className="h-2 col-lg-10 px-0 mb-5 mb-lg-8">
                        同じデータとモデルを<br/>再利用しているだけだから。
                    </h2>
                </div>
            </section>

            <section className="c-section py-5">
                <LazyLoad height={1100} once>
                    <div className="container">
                        <h3 className="h-3 mb-7">仕組み</h3>
                        <div className="row align-items-center">
                            <div className="col-md-4 text-center">
                                <Image
                                    maxWidth={240}
                                    maxHeight={200}
                                    mb={4}
                                    src={featureData}
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-5 col-md-8">
                                <h4 className="h-4 fw-800 mb-4">
                                    弊社のデータ：Weather of Things (WoT) ウェザー・オブ・シングス
                                </h4>
                                <p>
                                    IoT、ドローン、航空機、セルラー信号、衛星通信信号、ライブカメラ等といった、独自のリソースから収集した膨大なデータを従来のデータと統合。
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-4 text-center">
                                <Image
                                    maxWidth={200}
                                    maxHeight={200}
                                    mb={4}
                                    src={featureModels}
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-5 col-md-8">
                                <h4 className="h-4 fw-800 mb-4">MicroWeather モデル</h4>
                                <p>
                                    自社で研究開発した独自のモデルは、過去（AIモデルトレーニング）、リアルタイム、ナウキャスティング（分単位）、弊社のCBAMモデル（連日の予報）において最高の精度に達するために最適化。時間毎の代わりに分単位で、キロメーターの代わりに<br/>メーター単位で、グローバルに展開。
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-4 text-center">
                                <Image
                                    maxWidth={240}
                                    maxHeight={200}
                                    mb={4}
                                    src={featureEngine}
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-5 col-md-8">
                                <h4 className="h-4 fw-800 mb-4">
                                    MicroWeather テクノロジーエンジン
                                </h4>
                                <p>
                                    短い待ち時間、高い正確性、MicroWeatherオペレーティング<br/>システムツールとの統合を実現。気象データはビジネスの複雑な<br/>問題を解決するために最適化。
                                </p>
                            </div>
                        </div>
                    </div>
                </LazyLoad>
            </section>

            <section className="c-section py-5">
                <LazyLoad height={1100} once>
                    <div className="container">
                        <h3 className="h-3 mb-7">プロダクト</h3>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <CardProductHypercast/>
                            </div>
                            <div className="col-md-6">
                                <CardProductAPI/>
                            </div>
                        </div>
                        <CardProductWAI/>
                    </div>
                </LazyLoad>
            </section>

            <section className="c-section pb-0">
                <div className="container">
                    <h4 className="h-3 mb-6">弊社のモデル</h4>
                </div>
                <LazyLoad height={600} offset={800} once>
                    <ModelsTimeline/>
                </LazyLoad>
            </section>

            <section className="c-section">
                <div className="container">
                    <h4 className="h-3 mb-6">独自のグローバルソリューション</h4>
                    <LazyLoad height={600} offset={800} once>
                        <Solutions/>
                    </LazyLoad>
                </div>
            </section>

            <section className="c-section bg-light pb-0">
                <div className="container">
                    <h3 className="h-3 mb-7">検証報告書</h3>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <div
                                className="media p-4 rounded-lg bg-white h-50"
                                style={{border: "solid 1px #ebebeb", marginBottom: 30}}
                            >
                                <img width={86} src={reportBlue} className="mr-3" alt=""/>
                                <div className="media-body">
                                    <p className="text-muted mb-3">
                                    tomorrow.ioのNowCast（ナウキャスト）システムは、短時間で降水量探知を90%以上の確率で実現。
                                    </p>
                                    <a className="fw-900" href="https://www.tomorrow.io/request-validation/">
                                        全リポートをリクエスト ›
                                    </a>
                                </div>
                            </div>
                            <div
                                className="media p-4 rounded-lg bg-white h-50"
                                style={{border: "solid 1px #ebebeb"}}
                            >
                                <img width={86} src={reportGreen} className="mr-3" alt=""/>
                                <div className="media-body">
                                    <p className="text-muted mb-3">
                                    tomorrow.ioのCBAM気象予報は、政府モデルによる全変数を常に超える実績をグローバルに記録。
                                    </p>
                                    <a className="fw-900" href="https://www.tomorrow.io/request-validation/">
                                        全リポートをリクエスト ›
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div
                                className="d-flex p-4 flex-column rounded-lg bg-white h-100"
                                style={{border: "solid 1px #ebebeb"}}
                            >
                                <img width={166} src={embry} className="mb-3" alt=""/>
                                <p className="mb-3 text-muted">
                                tomorrow.ioは、現在画期的な短期気象予報システム（NowCastならびにCBAM）を運用している。実証試験を通して、大気圏の変数である降水量（NowCast）と地球全体の気温、雲量、風速（CBAM）を的確に予報することができており、ふたつのプロダクトは実に印象深く、ユニークな可能性を示している。NowCastならびにCBAMは、一般的に利用されている気象予報モデルデータを、特に短期間の予報において、かなりの精度と正確さにおいて上回っている。
                                </p>
                                <Box display="flex" alignItems="center" fontSize={2} mb={3}>
                                    <Image width={56} mr={3} src={milrad} alt=""/>
                                    <span><strong>ショーン・ミルラッド博士、</strong><br/> 准教授、気象学</span>
                                </Box>
                                <a className="fw-900" href="https://www.tomorrow.io/request-validation/">
                                    全リポートをリクエスト ›
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-6">
                            <div className="text-uppercase text-muted fs-sm text-center">
                            tomorrow.ioモデリングについての独立したレビュー
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="c-section bg-light">
                <div className="container">
                    <h3 className="h-3 mb-7">新興国における弊社の取り組み</h3>
                    <CardProductOrg/>
                </div>
            </section>

            <section
                className="c-section bg-primary"
                style={{background: `url(${footer}) no-repeat bottom right / contain`}}
            >
                <div className="container py-3">
                    <h3 className="h-2 text-white text-center mb-5">
                        ご関心がございますか？
                    </h3>
                    <div className="text-center">
                        <a
                            className="btn btn-lg btn-outline-white"
                            href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            id="hp-form-link-micro-3"
                        >
                            打合せのお申込み
                        </a>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

const mapSizesToProps = ({width}) => ({
    isMobile: width < 769
});

export default withSizes(mapSizesToProps)(Home);
